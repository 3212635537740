import { init } from '@mocobaas/client-js';
import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import { EncryptionOptions } from '@mocobaas/client-js/build/PayloadEncryption';

class Mbaas {
  backendUrl: string;
  storageUrl: string;
  host: string;
  mbaasClient: MobileServiceClient;

  constructor() {
    this.host = window.location.host;
    // BE
    const beProd = 'https://partner-api.mkios.telin.my';
    const beTest = 'https://api.sandbox.mkios.telin.my';
    // Storage
    const storageProd = 'https://nos.wjv-1.neo.id';
    const storageTest = 'https://testing.tmy-storage.telin.com.my';
    //
    let enforcement: EncryptionOptions['enforcement'] = [];
    if (this.host === 'mkios.telin.my') {
      this.backendUrl = beProd;
      this.storageUrl = storageProd;
      enforcement = ['COMMON', 'FUNCTION', 'TABLE', 'GRAPHQL'];
    } else {
      this.backendUrl = beTest;
      this.storageUrl = storageTest;
    }
    this.mbaasClient = new init(this.backendUrl, {
      payloadEncryption: {
        enforcement,
      },
    });
  }

  get isProduction() {
    return this.host === 'mkios.telin.my';
  }

  get appURL() {
    return this.host;
  }

  get client() {
    return this.mbaasClient;
  }
}

export default new Mbaas();
